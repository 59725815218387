import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Form from '../components/form';

const BackgroundSection = ({ className }) => (
	<StaticQuery
		query={graphql`
			query {
				desktop: file(relativePath: { eq: "roses.jpg" }) {
					childImageSharp {
						fluid(quality: 90, maxWidth: 4160) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`}
		render={data => {
			// Set ImageData.
			const imageData = data.desktop.childImageSharp.fluid;
			return (
				<Layout>
					<BackgroundImage Tag="section" className={className} fluid={imageData} backgroundColor={`#040e18`}>
						<SEO title="Milwaukee Flower Wall Rental Homepage" keywords={[`milwaukee flower wall`, `flower wall rental`, `milwaukee flower wall rental`, `wedding flower wall`]} />
						<div className="absolute top-0 right-0 left-0 bottom-0 bg-red-dark opacity-50"></div>
						<div className="relative mb-24 px-4">
							<header className="relative z-2 w-full text-center text-white ">
								<h1 className="w-full text-2xl md:text-4xl lg:text-6xl font-light tracking-wider mb-4">
									MILWAUKEE <span className="block lg:inline-block">FLOWER WALL</span>
								</h1>
								<p className="text-md md:text-lg font-semibold text-red-lighter tracking-widest mb-6 lg:mb-12">RENT A BEAUTIFUL FLOWER WALL FOR YOUR NEXT EVENT</p>
							</header>
							<section class="relative z-2 w-full md:w-3/4 lg:w-1/2 mx-auto">
								<Form />
							</section>
						</div>
					</BackgroundImage>
				</Layout>
			);
		}}
	/>
);

const StyledBackgroundSection = styled(BackgroundSection)`
	width: 100%;
	position: relative;
	background-position: bottom center;
	background-repeat: repeat-y;
	background-size: cover;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default StyledBackgroundSection;
