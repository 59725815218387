import React from 'react';

export default function Form() {
	return (
		<form class="w-full flex flex-wrap text-md md:text-lg" action="/success" name="contact" method="POST" data-netlify="true">
			<input type="hidden" name="form-name" value="contact" />
			<p class="bg-white flex-auto font-semibold">
				<label>
					<span class="sr-only">Email</span>
					<input required class="border-2 border-black text-sm py-2 px-4 w-full h-full text-center" type="email" name="email" placeholder="yourbestemail@gmail.com" />
				</label>
			</p>
			<button class="bg-black text-white flex-1 font-semibold tracking-widest py-2 px-3" type="submit">
				GET A QUOTE
			</button>
		</form>
	);
}
